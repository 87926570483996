import '../styles/globals.css';
import 'remixicon/fonts/remixicon.css';
import Script from 'next/script';
import Layout from '../components/layout';
import Head from 'next/head';

export default function App({ Component, pageProps }) {
  return (
    <>
      <Head>
        <meta name="description" content="I&apos;m a Fullstack Web hApplication Developer along with experience in Cloud Web Hosting, Web Security, and Core Web Vitals Optimization." />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content="Aryan Chaurasia" />
        <meta name="twitter:description" content="I&apos;m a Fullstack Web Application Developer along with experience in Cloud Web Hosting, Web Security, and Core Web Vitals Optimization." />
        <meta name="twitter:label1" content="Developed by" />
        <meta name="twitter:data1" content="thehungrybird" />
        <meta name="google-site-verification" content="FCQXVawJ5zz4LEeeVFkUHYMBOosrN5PfEl-Q3fbpYxQ" />
      </Head>
      <Script strategy="lazyOnload" src={`https://www.googletagmanager.com/gtag/js?id=${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}`} />
      <Script strategy="lazyOnload" id="Google Analytics">
                {`
                    window.dataLayer = window.dataLayer || [];
                    function gtag(){dataLayer.push(arguments);}
                    gtag('js', new Date());
                    gtag('config', '${process.env.NEXT_PUBLIC_GOOGLE_ANALYTICS}', {
                    page_path: window.location.pathname,
                    });
                `}
      </Script>
      <Layout>
        <Component {...pageProps} />
      </Layout>
    </>
  );
}
