import Link from "next/link";

function Footer (){
    return (
        <footer className="py-2 left-0 bottom-0 items-center gap-5 justify-start">
            {/* <Link href={"/"}>Aryan</Link> */}
            {/* <Image src={LogoImage} alt="Aryan Chaurasia" width={200} height={100} quality={100} /> */}
            <nav className="flex items-center gap-5 justify-start">
                <Link href="/policy">Policies</Link>
                <Link href="/contact-me">Contact Me</Link>
            </nav>

        </footer>
    );
}

export default Footer;